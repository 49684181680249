(function (apiKey) {
  var url = window.location.href;
  var isPilotOrProdUI = url.includes('secureworks.com') || url.includes('ctpx.pilot.8labs.io');
  if (!isPilotOrProdUI) {
    return;
  }

  var isExcludedRoute =
    url.includes('generate-pdf-report') ||
    url.includes('generate-predefined-pdf-report') ||
    url.includes('generate-investigation-pdf-report') ||
    url.includes('generate-custom-dashboard-report');
  if (isExcludedRoute) {
    return;
  }

  var isDataDogAgentRequest = (document.cookie || '').includes('datadog-agent');
  if (isDataDogAgentRequest) {
    return; // avoid pendo init when the page is accessed from data dog (CX-77493)
  }

  (function (p, e, n, d, o) {
    var v, w, x, y, z;
    o = p[d] = p[d] || {};
    o._q = [];
    v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
    for (w = 0, x = v.length; w < x; ++w)
      (function (m) {
        o[m] =
          o[m] ||
          function () {
            o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
          };
      })(v[w]);
    y = e.createElement(n);
    y.async = !0;
    y.src = 'https://content.analytics.ctpx.secureworks.com/agent/static/' + apiKey + '/pendo.js';
    z = e.getElementsByTagName(n)[0];
    z.parentNode.insertBefore(y, z);
  })(window, document, 'script', 'pendo');

  var locale = readCookie('locale');
  if (!locale) {
    var langArr = navigator.languages;
    locale = Array.isArray(langArr) ? langArr[0] : navigator.language;
  }

  window['pendo'].initialize({
    visitor: {
      id: 'VISITOR-UNIQUE-ID',
      locale: locale,
    },
  });
})('185cf4c3-3299-4360-7cef-d77eb6d7f36e');

function readCookie(name) {
  var nameEQ = `${name}=`;
  var cookieArr = document.cookie.split(';');
  for (var i = 0; i < cookieArr.length; i++) {
    var c = cookieArr[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
